<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            label="기존 비밀번호"
            label-for="account-old-password"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="passwordValueOld"
                name="old-password"
                :type="passwordFieldTypeOld"
                placeholder="기존 비밀번호"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="account-new-password"
            label="신규 비밀번호"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                v-model="newPasswordValue"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="신규 비밀번호"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            label="신규 비밀번호 확인"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="RetypePassword"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                placeholder="신규 비밀번호 확인"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click.prevent="submitForm"
          >
            수정
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
            @click.prevent="resetForm"
          >
            초기화
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
  import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  } from 'bootstrap-vue';
  import Ripple from 'vue-ripple-directive';

  export default {
    components: {
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BCard,
      BInputGroup,
      BInputGroupAppend,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: JSON.parse(localStorage.getItem('userData')),
        passwordValueOld: '',
        newPasswordValue: '',
        RetypePassword: '',
        passwordFieldTypeOld: 'password',
        passwordFieldTypeNew: 'password',
        passwordFieldTypeRetype: 'password',
      };
    },
    computed: {
      passwordToggleIconOld() {
        return this.passwordFieldTypeOld === 'password'
          ? 'EyeIcon'
          : 'EyeOffIcon';
      },
      passwordToggleIconNew() {
        return this.passwordFieldTypeNew === 'password'
          ? 'EyeIcon'
          : 'EyeOffIcon';
      },
      passwordToggleIconRetype() {
        return this.passwordFieldTypeRetype === 'password'
          ? 'EyeIcon'
          : 'EyeOffIcon';
      },
    },
    methods: {
      togglePasswordOld() {
        this.passwordFieldTypeOld =
          this.passwordFieldTypeOld === 'password' ? 'text' : 'password';
      },
      togglePasswordNew() {
        this.passwordFieldTypeNew =
          this.passwordFieldTypeNew === 'password' ? 'text' : 'password';
      },
      togglePasswordRetype() {
        this.passwordFieldTypeRetype =
          this.passwordFieldTypeRetype === 'password' ? 'text' : 'password';
      },
      resetForm() {
        this.passwordValueOld = '';
        this.newPasswordValue = '';
        this.RetypePassword = '';
      },
      submitForm() {
        if (this.passwordValueOld.trim() === '') {
          alert('기존 비밀번호를 입력해 주세욧.');
          return;
        }

        if (this.newPasswordValue.trim() === '') {
          alert('신규 비밀번호를 입력해 주세욧.');
          return;
        }
        if (this.RetypePassword.trim() === '') {
          alert('신규 비밀번호를 재입력해 주세욧.');
          return;
        }

        if (this.newPasswordValue.trim() !== this.RetypePassword.trim()) {
          alert('신규 비밀번호가 서로 다릅니다.');
          return;
        }

        this.$http
          .post(`/user/account-settings/password`, {
            id: this.userData.id,
            passwordValueOld: this.passwordValueOld,
            newPasswordValue: this.newPasswordValue,
            RetypePassword: this.RetypePassword,
          })
          .then(res => {
            // TODO: userData refresh code
            if (res.status === 200) {
              alert('수정되었습니다.');
            } else {
              alert('저장에 실패했습니다.');
            }
          });
      },
    },
  };
</script>
