<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="회사명"
            label-for="comName"
          >
            <b-form-input
              v-model="localOptions.comName"
              name="comName"
              placeholder="회사명"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="회사 전화번호"
            label-for="comPhone"
          >
            <b-form-input
              v-model="localOptions.comPhone"
              name="comPhone"
              placeholder="회사 전화번호"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="회사 주소"
            label-for="address1"
          >
            <b-form-input
              v-model="localOptions.address1"
              name="address1"
              placeholder="회사 주소"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="자택 전화번호"
            label-for="homePhone"
          >
            <b-form-input
              v-model="localOptions.homePhone"
              name="homePhone"
              placeholder="자택 전화번호"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="자택 주소"
            label-for="address2"
          >
            <b-form-input
              v-model="localOptions.address2"
              name="address2"
              placeholder="자택 주소"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click.prevent="submitForm"
          >
            저장
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            초기화
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
  import {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormSelect,
  } from 'bootstrap-vue';
  import vSelect from 'vue-select';
  import flatPickr from 'vue-flatpickr-component';
  import { Korean } from 'flatpickr/dist/l10n/ko.js';
  flatpickr.localize(Korean);
  import Ripple from 'vue-ripple-directive';
  import Cleave from 'vue-cleave-component';

  // eslint-disable-next-line import/no-extraneous-dependencies
  import 'cleave.js/dist/addons/cleave-phone.kr';

  export default {
    components: {
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BCard,
      BFormTextarea,
      BInputGroup,
      BInputGroupAppend,
      BInputGroupPrepend,
      BFormSelect,
      vSelect,
      flatPickr,
      Cleave,
    },
    directives: {
      Ripple,
    },
    props: {
      informationData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        localOptions: JSON.parse(JSON.stringify(this.informationData)),
        clevePhone: {
          phone: true,
          phoneRegionCode: 'KO',
        },
      };
    },
    methods: {
      resetForm() {
        this.localOptions = JSON.parse(JSON.stringify(this.informationData));
      },
      submitForm() {
        this.$http
          .post(`/user/account-settings/info`, this.localOptions)
          .then(res => {
            // TODO: userData refresh code
            if (res.status === 200) {
              // TODO: sweetalert 사용

              let userData = { ...res.data };
              if (userData.role === 'manager') {
                userData.ability = [{ action: 'manage', subject: 'all' }];
              } else if (user.role === 'member') {
                userData.ability = [
                  { action: 'read', subject: 'ACL' },
                  { action: 'read', subject: 'Auth' },
                ];
              }

              localStorage.setItem('userData', JSON.stringify(userData));
              alert('수정되었습니다.');
            } else {
              alert('저장에 실패했습니다.');
            }
          });
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
