<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <!-- media -->
          <b-media no-body>
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  :src="optionsLocal.profileUrl"
                  height="80"
                />
              </b-link>
              <!--/ avatar -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="$refs.refInputEl.$el.click()"
              >
                업로드
              </b-button>
              <b-form-file
                ref="refInputEl"
                id="profileImage"
                v-model="profileFile"
                accept=".jpg, .png, .gif"
                :hidden="true"
                plain
                @input="inputImageRenderer"
              />
              <!--/ upload button -->

              <!-- reset -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                size="sm"
                class="mb-75 mr-75"
                @click.prevent="resetImage"
              >
                초기화
              </b-button>
              <!--/ reset -->
              <b-card-text>JPG, GIF, PNG 만 가능. 최대 800 KB</b-card-text>
            </b-media-body>
          </b-media>
          <!--/ media -->
        </b-col>
        <b-col md="6">
          <b-form-group
            label="이름"
            label-for="account-username"
          >
            <b-form-input
              v-model="optionsLocal.userName"
              placeholder="성명"
              name="userName"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="이메일"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="optionsLocal.email"
              name="email"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label-for="mobile"
            label="핸드폰"
          >
            <cleave
              id="mobile"
              v-model="optionsLocal.mobile"
              class="form-control"
              :raw="false"
              :options="clevePhone"
              placeholder="핸드폰 번호"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            type="submit"
            @click.prevent="submitForm"
          >
            수정
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            초기화
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
  import {
    BFormFile,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormSelect,
  } from 'bootstrap-vue';
  import Ripple from 'vue-ripple-directive';
  import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
  import { ref } from '@vue/composition-api';
  import Cleave from 'vue-cleave-component';

  import 'cleave.js/dist/addons/cleave-phone.kr';

  export default {
    components: {
      BButton,
      BForm,
      BImg,
      BFormFile,
      BFormGroup,
      BFormInput,
      BRow,
      BCol,
      BCard,
      BCardText,
      BMedia,
      BMediaAside,
      BMediaBody,
      BLink,
      BInputGroup,
      BInputGroupAppend,
      BInputGroupPrepend,
      BFormSelect,
      Cleave,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        profileFile: null,
        clevePhone: {
          phone: true,
          delimiter: '-',
          phoneRegionCode: 'KR',
        },
      };
    },
    methods: {
      resetForm() {
        this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
      },
      resetImage() {
        this.profileFile = null;
        // this.optionsLocal.profileImage = '';
        this.optionsLocal.profileUrl = require('@/assets/images/avatars/avatar.jpeg');
      },
      submitForm() {
        const formData = new FormData();
        // Append the file - TODO: 수정시 처리
        formData.append('profileImage', this.profileFile);
        formData.append('profileImageOld', this.optionsLocal.profileImage);

        formData.append('id', this.optionsLocal.id);
        formData.append('userName', this.optionsLocal.userName);
        formData.append('email', this.optionsLocal.email);
        formData.append('mobile', this.optionsLocal.mobile);

        // console.log(JSON.stringify(this.post));
        // console.log(formData);

        // axios 파일업로드 헤더 설정
        this.$http.defaults.headers.post['Content-Type'] =
          'multipart/form-data';

        this.$http
          .post(`/user/account-settings/general`, formData)
          .then(res => {
            // TODO: userData refresh code
            if (res.status === 200) {
              let userData = { ...res.data };
              if (userData.role === 'manager') {
                userData.ability = [{ action: 'manage', subject: 'all' }];
              } else if (userData.role === 'member') {
                userData.ability = [
                  { action: 'read', subject: 'ACL' },
                  { action: 'read', subject: 'Auth' },
                ];
              }
              localStorage.setItem('userData', JSON.stringify(userData));
              alert('수정되었습니다.');
            } else {
              alert('저장에 실패했습니다.');
            }
          });
      },
    },
    setup() {
      const refInputEl = ref(null);
      const previewEl = ref(null);

      const { inputImageRenderer } = useInputImageRenderer(
        refInputEl,
        base64 => {
          previewEl.value.src = base64;
        }
      );

      return {
        refInputEl,
        previewEl,
        inputImageRenderer,
      };
    },
  };
</script>
