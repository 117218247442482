<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">기본정보</span>
      </template>

      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">비밀번호 변경</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">상세정보</span>
      </template>

      <account-setting-information
        v-if="options.info"
        :information-data="options.info"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
  import { BTabs, BTab } from 'bootstrap-vue';
  import AccountSettingGeneral from './AccountSettingGeneral.vue';
  import AccountSettingPassword from './AccountSettingPassword.vue';
  import AccountSettingInformation from './AccountSettingInformation.vue';

  export default {
    components: {
      BTabs,
      BTab,
      AccountSettingGeneral,
      AccountSettingPassword,
      AccountSettingInformation,
    },
    data() {
      return {
        // userData: JSON.parse(localStorage.getItem('userData')),
        userData: {},
        options: {},
      };
    },
    created() {
      this.$http.get('/user/account-settings').then(res => {
        this.userData = res.data[0];
        this.options = {
          general: {
            id: this.userData.id,
            profileUrl: this.userData.profileImage
              ? `/uploads/profileimage/3/${this.userData.profileImage}`
              : require('@/assets/images/avatars/avatar.jpeg'),
            profileImage: this.userData.profileImage,
            userId: this.userData.userId,
            userName: this.userData.userName,
            email: this.userData.email,
            genName: this.userData.genName,
            mobile: this.userData.mobile,
          },
          info: {
            id: this.userData.id,
            homePhone: this.userData.homePhone,
            address2: this.userData.address2,
            comName: this.userData.comName,
            comPhone: this.userData.comPhone,
            address1: this.userData.address1,
          },
        };
      });
    },
  };
</script>
